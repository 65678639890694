<div class="row">
  <div class="col-md-12">
    <div class="error-template">
      <h1>Oops!</h1>
      <h2>404 Page non trouvée</h2>
      <div class="error-details">
        Désolé, une erreur c'est produite, la page demandée n'a pas été trouvée!
      </div>
      <div class="error-actions">
        <a class="btn btn-primary btn-lg" routerLink="/home">
          <i class="bi bi-house-door"></i>
          Retour à l'accueil
        </a>
      </div>
    </div>
  </div>
</div>
